import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import {
  ALIGN,
  BUTTON_STYLE,
  COLOR,
  MARGIN
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Heading4, Heading3 } from '@latitude/heading';
import HowToApplyComponent, {
  InnerContainer,
  ContentBox
} from '@latitude/how-to-apply';
import { Vertical } from '@latitude/spacing';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { FeaturesSlider } from '@latitude/features-slider';
import { PageContext } from '@/context/PageContext';
import { BREAKPOINT, CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

import imageShopping from './images/shopping.svg';
import imageSunglasses from './images/sunglasses.svg';

const WhyChoose = () => {
  const featureSliderItems = [
    {
      icon: 'icon-raised-fist',
      title: 'Pay off early. No worries.',
      text:
        'Want to pay off your Interest Free plan early? Go for it, there are no penalties.'
    },
    {
      icon: 'icon-everyday',
      title: 'The perfect card, everyday.',
      text:
        "The Latitude Gem Visa offers up to 55 days interest free<sup>1</sup>  on everyday purchases, so it's the perfect go-to card for your everyday spending too."
    },
    {
      icon: 'icon-cardless-branded',
      title: 'Ready, set, tap.',
      text:
        'With our instant cardless payment feature, you can start shopping from your phone in minutes.'
    },
    {
      icon: 'icon-stay-on-track',
      title: 'Managing your account made easy.',
      text:
        'The <a href="/mobile-app/">Latitude App</a> makes it easier than ever to track spending and manage repayments.'
    },
    {
      icon: 'icon-pl-2',
      title: 'Booking.com partnership',
      jsx:(<p
        css={`
        font-family: Roboto, Open Sans, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-align: center;
        color: #42484d;
        `}>
        Get 6% off your stay instantly with <a href="/travelpartners">Booking.com</a> every day, all year round via the Latitude App<sup>3</sup>.</p>)
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    featureSliderData: [{
      featureCards: featureSliderItems
    }]
  });

  useEffect(() => {
    let [featureSliders] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        default:
          break;
      }
    });

    setState({ 
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <>
      <AnalyticsLocationProvider location="Key advantages of Latitude Gem Visa">
        <Box
          id="why-us"
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 50px 0px 0px 0px;
            z-index: 1;
          `}
        >
          <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} isResponsive>
            Key advantages of Latitude Gem Visa
          </Heading4>

          <HowToApplyComponent
            css={`
              ${InnerContainer} {
                background-color: #e8f4ff;
              }
              ${ContentBox} {
                border-color: #b1dbff;
              }
            `}
            box1={
              <Vertical spacing={MARGIN.M16}>
                <img
                  src={imageShopping}
                  alt="Interest Free payment plans at thousands of stores."
                />
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  isResponsive={false}
                >
                  Interest Free payment plans at thousands of stores.
                </Heading3>
                <Text align={ALIGN.CENTER}>
                  Plans from 6&ndash;60 months. Perfect for bigger purchases.
                </Text>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="/interest-free"
                  data-trackid="find-out-more-interest-free-payment-plans"
                  target="_self"
                  trackEventData={{
                    label: 'Find out more about interest free plans'
                  }}
                >
                  Find out more
                </Link>
              </Vertical>
            }
            box2={
              <Vertical spacing={MARGIN.M16}>
                <img
                  src={imageSunglasses}
                  alt="Instant 6 months Interest Free on purchases $250 and over."
                />
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  isResponsive={false}
                >
                  Instant 6 months Interest Free on purchases $250 and over.
                </Heading3>
                <Text align={ALIGN.CENTER}>
                  Anywhere, anytime with your Latitude Gem Visa.
                </Text>
              </Vertical>
            }
          />
        </Box>
      </AnalyticsLocationProvider>

      {state?.featureSliderData?.[0] && (
        <Box
          backgroundColor={COLOR.GREY6}
          css={`
            position: relative;
            z-index: 1;
          `}
        >
          <FeaturesSlider
            newBrand
            data={state.featureSliderData[0].featureCards}
            heading={state.featureSliderData[0].heading}
            subheading={state.featureSliderData[0].description}
          />
        </Box>
      )}
    </>
  );
};

export default WhyChoose;

import React from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import RatesAndFeesComponent from '@latitude/rates-and-fees';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';

const heading = 'Rates, Fees and Conditions';

const RatesAndFees = () => (
  <div id="rates-and-fees">
    <AnalyticsLocationProvider location={heading}>
      <RatesAndFeesComponent
        id="rates"
        css={`
          button.link-block {
            background-color: ${COLOR.GREY5};
            cursor: pointer;
          }
          .accordion-custom .accordion__row {
            padding: 5px 0;
            margin: 0 30px;
          }
        `}
        title="Rates & Fees"
        ratesListProps={{
          data: [
            {
              largeText: '0%',
              smallText: 'for 6 months on everyday purchases $250 and over'
            },
            {
              largeText: '0%',
              smallText: 'on Interest Free Payment Plans'
            },
            {
              largeText: '0%',
              smallText:
                'up to 55 days interest free<sup>1</sup> on everyday purchases'
            },
            {
              largeText: '27.49',
              showExtras: true,
              smallText: 'interest rate on everyday purchases<sup>+</sup>'
            },
            {
              largeText: '$10.95',
              smallText: 'monthly account service fee (subject to change)'
            }
          ]
        }}
        accordionProps={{
          items: [
            {
              id: 'other-fees',
              title: 'Other rates & fees',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Expired Interest Free Payment Plans</strong>
                    </p>
                    <p className="w-100">29.99% p.a.</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Interest rate on cash advances</strong>
                    </p>
                    <p className="w-100">29.99% p.a.</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Interest rate on balance transfers</strong>
                    </p>
                    <p className="w-100">
                      Please refer to the Balance Transfer interest rate, term
                      and balance transfer fee set out in any offer presented to
                      you.
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Cash advance fee</strong>
                    </p>
                    <p className="w-100">The greater of 3.5% or $4.</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>International transaction fee</strong>
                    </p>
                    <p className="w-100">
                      3% on all purchases and cash advances
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Payment handling fees</strong>
                    </p>
                    <p className="w-100">
                      <AnalyticsLocationProvider location="Payment handling fees">
                        $0 when you pay via the{' '}
                        <Link href="/mobile-app/">Latitude App</Link>
                        {', '}
                        <Link href="https://online.gemvisa.com.au/access/login">
                          Online Service Centre
                        </Link>
                        , mail, or direct debit
                        <br /> <br />
                        $1.95 (online using BPAY<sup>®</sup>)
                        <br /> <br />
                        $3.95 (in person at Australia Post)
                      </AnalyticsLocationProvider>
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Late fee</strong>
                    </p>
                    <p className="w-100">$45</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Online statements</strong>
                    </p>
                    <p className="w-100">
                      <AnalyticsLocationProvider location="Online statements">
                        $0 by opting to receive statements online. Simply login
                        to the{' '}
                        <Link href="https://online.gemvisa.com.au/access/login">
                          Online Service Centre
                        </Link>
                        .
                      </AnalyticsLocationProvider>
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Paper statements</strong>
                    </p>
                    <p className="w-100">
                      <AnalyticsLocationProvider location="Paper statements">
                        $5.00
                        <br />
                        You can avoid this fee by opting to receive statements
                        online. Simply login to the{' '}
                        <Link href="https://online.gemvisa.com.au/access/login">
                          Online Service Centre
                        </Link>
                        .
                      </AnalyticsLocationProvider>
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Balance transfer fee</strong>
                    </p>
                    <p className="w-100">Up to 3% of balance transfer amount</p>
                  </div>
                </div>
              )
            },
            {
              id: 'other-information',
              title: 'Other information',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100 col-sm-9">
                      Before making a decision, please read these documents to
                      ensure you&apos;re aware of all fees, charges and interest
                      rates.
                    </p>
                    <p className="w-100 col-sm-3">
                      <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/kfs.html">
                        Key facts sheet
                      </Link>
                      <br />
                      <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf">
                        Conditions of use
                      </Link>
                    </p>
                  </div>
                </div>
              )
            }
          ]
        }}
      />
    </AnalyticsLocationProvider>
  </div>
);

export default RatesAndFees;
